const reducers = (state, action) => {
  console.log('REDUCER: action.... ', action);

  switch (action.type) {
    case 'INIT':
      const initVal = localStorage.getItem('secret');
      const initRet = {};

      if (initVal !== 'a3lsZWtlbGx5a2Vuem8=') {
        localStorage.setItem('secret', '');
      } else {
        initRet.secret = initVal;
      }
      return { ...state, ...initRet };

    case 'GET_SECRET':
      const getSecretVal = localStorage.getItem('secret');

      if (getSecretVal && getSecretVal === 'a3lsZWtlbGx5a2Vuem8=') {
        return { ...state, secret: getSecretVal };
      } else {
        localStorage.setItem('secret', '');
        return { ...state, secret: '' };
      }

    case 'SET_SECRET':
      const setSecretVal = window.btoa(action.provider);
      const setSecretRet = {};

      if (setSecretVal === 'a3lsZWtlbGx5a2Vuem8=') {
        localStorage.setItem('secret', setSecretVal);
        setSecretRet.secret = setSecretVal;
      }
      return { ...state, ...setSecretRet };

      case 'CHECK_SECRET':
        return {...state};
    case 'SET_ASSETS':
      return { ...state, assets: action.provider };

    case 'REMOVE_SECRET':
      localStorage.setItem('secret', '');

      return { ...state, secret: '' };
  
    case 'REMOVEERROR':
      return { ...state, error: '' };

    default:
      throw new Error();
  }
};

export default reducers;
