import React from 'react';
// import { MainContext } from '../state/connect';
// import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
// import Lightbox from 'react-awesome-lightbox';
import 'react-vertical-timeline-component/style.min.css';
// import 'react-awesome-lightbox/build/style.css';
import './style.scss';
import ZoHeader from '../ZoHeader';

const Zo = () => {
  // const { state } = React.useContext(MainContext);
  // const [dis, setDis] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState({});
  // const [startIndex, setStartIndex] = React.useState(0);
  // const assets = process.env.REACT_APP_ASSET_PATH;
  // const bg = '#02182B';

  const openFunc = (i) => {
    console.log('iii', i.target.files)
    setSelectedFile(i.target.files[0])
    // setStartIndex(i);
    // setDis(!dis);
  };

  return (
    <div className="App">
      <ZoHeader />

      <div style={{ marginBottom: '50px', color: 'whitesmoke' }}>
        <input className="file-upload-input" type='file' onChange={openFunc} accept="image/*" />
        {selectedFile.name && (
        <div><p>File Name: {selectedFile.name}</p>
        <p>File Type: {selectedFile.type}</p>
        <p>Last Modified:{" "}{new Date(selectedFile.lastModified).toDateString()}</p>
        <div className='image-upload-cont'>
          <img src={URL.createObjectURL(selectedFile)} alt='uploaded' />
        </div>
        </div>
        )
        }
      </div>

      <footer className="footer">
        <span className="footer-managed">Made with ❤️ by Kyle Brown</span>
      </footer>
    </div>
  );
};

export default Zo;
