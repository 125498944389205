import React from 'react';
import { MainContext } from './state/connect';
import { Route } from 'react-router-dom';
import Home from './Home';
// import Zo from './Zo';

const PrivateRoute = ({ component: Component, ...rest}) => {
  const { state } = React.useContext(MainContext);

  return (
      <Route {...rest} render={(props) => {
        return state.secret !== '' ? <Component {...props} /> : <Home />
      }} />
  );
};

export default PrivateRoute;
