import React from 'react';
import './App.scss';
import { Provider } from './state/connect';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './Routes';

const App = () => (
  <Router>
    <Provider>
      <Routes />
    </Provider>
  </Router>
);

export default App;
