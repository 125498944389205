import React from 'react';
import { MainContext } from '../state/connect';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import Lightbox from 'react-awesome-lightbox';
import 'react-vertical-timeline-component/style.min.css';
import 'react-awesome-lightbox/build/style.css';
import './style.scss';
import ZoHeader from '../ZoHeader';

const Zo = () => {
  const { state } = React.useContext(MainContext);
  const [dis, setDis] = React.useState(false);
  const [startIndex, setStartIndex] = React.useState(0);
  const assets = process.env.REACT_APP_ASSET_PATH;
  const bg = '#02182B';
  const images =
    state.assets.length > 0 &&
    state.assets
      .reduce((acc, v, i) => {
        if (v.Size > 0) {
          acc.push({
            url: `${assets}/${v.Key}`,
            title: v.Key,
            type: v.Key.split('.')[v.Key.split('.').length - 1],
            date: v.Key.split('XX')[1].split('.')[0],
          });
        }
        return acc;
      }, [])
      .sort((a, b) => (a.date > b.date ? -1 : 1));

  const openFunc = (i) => {
    setStartIndex(i);
    setDis(!dis);
  };

  return (
    <div className="App">
      <ZoHeader />

      <div style={{ marginBottom: '50px' }}>
        {state.assets.length > 0 && dis && (
          <Lightbox images={images} startIndex={startIndex} onClose={()=>openFunc(0)} />
        )}
        {state.assets.length > 0 && (
          <VerticalTimeline>
            {images.map((v, i) => {
              if (v.type.toUpperCase() === 'MOV') {
                return (
                  <VerticalTimelineElement
                    key={i}
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: `${bg}`, color: '#fff' }}
                    contentArrowStyle={{ borderRight: `7px solid  ${bg}` }}
                    date={v.date}
                    iconStyle={{ background: '#000', color: '#fff' }}
                    icon={<img  className='timeline-icon' src="/video.svg" alt='baby' />}>
                    <video className="timeline-video" controls>
                      <source src={v.url} type="video/mp4"></source>
                    </video>
                  </VerticalTimelineElement>
                );
              } else {
                return (
                  <VerticalTimelineElement
                    key={i}
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: `${bg}`, color: '#fff' }}
                    contentArrowStyle={{ borderRight: `7px solid  ${bg}` }}
                    date={v.date}
                    iconStyle={{ background: '#f8f5f1', color: '#fff' }}
                    icon={<img className='timeline-icon' src="/picture.svg" alt='baby' />}>
                    <div
                      className="baby-img"
                      style={{ backgroundImage: `url(${v.url})` }}
                      onClick={() => {
                        openFunc(i);
                      }}
                    />
                  </VerticalTimelineElement>
                );
              }
            })}
          </VerticalTimeline>
        )}
      </div>

      <footer className="footer">
        <span className="footer-managed">Made with ❤️ by Kyle Brown</span>
      </footer>
    </div>
  );
};

export default Zo;
