import React from 'react';
import SecretForm from '../SecretForm';
import ZoHeader from '../ZoHeader';

const Home = () => (
  <div className="App">
    <ZoHeader noX />

    <SecretForm />

    <footer className="footer">
      <span className="footer-managed">Made with ❤️ by Kyle Brown</span>
    </footer>
  </div>
);

export default Home;
