import React from 'react';
import { MainContext } from './state/connect';
import { Switch, Route } from 'react-router-dom';
import Home from './Home';
import Zo from './Zo';
import Upload from './Upload';
import PrivateRoute from './PrivateRoute';

const Routes = () => {
  const { state } = React.useContext(MainContext);

  // if (state.secret === '') {
  //   return (<Home />);
  // }

  return (
    <Switch>
      <PrivateRoute exact path="/" component={Zo} />
      <Route exact path="/upload" component={Upload} />
    </Switch>
  );
};

export default Routes;
