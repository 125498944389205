import React from 'react';
import { MainContext } from '../state/connect';
// import { useHistory } from 'react-router-dom';

const SecretForm = () => {
  // const history = useHistory();
  // history.push('my-stuff');

  const { state, dispatch } = React.useContext(MainContext);
  const inputReference = React.useRef();

  // console.log('Secret stage , ', state);
  const clickk = (e) => {
    // console.log('clicked before', inputReference);
    dispatch({type: "SET_SECRET", provider: inputReference.current.value});
    // console.log('adfters');
  };
  console.log('secret form', state)
  return (
    <div className="bod">
      <div className="centerer mider">
        <label htmlFor='input-secret'>Give me your secret: </label>
        <div className="input-cont">
          <input ref={inputReference} className="input-secret" id='input-secret' type="text" autoFocus />
        </div>
        <div className="bod-button" onClick={clickk}>
          Submit
        </div>
      </div>
    </div>
  );
};

export default SecretForm;
