import React from 'react';
import { MainContext } from '../state/connect';
import { useHistory, useLocation, Redirect } from 'react-router-dom';

const ZoHeader = ({noX}) => {
  const { state, dispatch } = React.useContext(MainContext);
  const history = useHistory();
  const location = useLocation();

  const clicker = () => {
    dispatch({ type: 'REMOVE_SECRET' });
  };
  const clickerUpload = () => {
      history.push('/upload');
  };

  React.useEffect(()=> {
    if (location.pathname === '/upload' &&  state.secret === '') {
      history.push('/');
    }
  }, [state.secret])

  return (
    <header className="header">
      {!noX && (<span onClick={clickerUpload} className="header-upload"><img alt='upload' src={'/upload.svg'} /></span>)}
      <h1>Kenzo</h1>
      {!noX && (<span onClick={clicker} className="header-remove-secret"><img alt='exit' src={'/exit.svg'} /></span>)}
    </header>
  );
};

export default ZoHeader;
