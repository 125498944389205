/* eslint-disable react-hooks/exhaustive-deps */
// change init state pobject here
import React, {createContext, useEffect, useReducer} from 'react';
import { useHistory } from 'react-router-dom';

import reducers from './reducers';
// import farmersMarket from './json/farmersmarket.json';
// import petStore from './json/pets.json';
// import user from './json/user.json';

// const storeData = ({key, val}) => {
//   try {
//     localStorage.setItem(key, val);
//     // await AsyncStorage.setItem(val.key, val.val);
//   } catch (e) {}
// };
const MainContext = createContext({});

const Provider = ({children}) => {
    const history = useHistory();
  // history.push('my-stuff');
  const apiUrl = process.env.REACT_APP_API_PATH;
  const key = 'zozo';
  const [state, dispatch] = useReducer(reducers, { localValue: key, secret: '', assets: [] });

  // fetch(`/check`, { method: 'GET'})
  //   .then((response) => response.json())
  //   .then(({loggedIn}) => {
  //     !loggedIn && history.push('login');
  //   })
  // change data attr to update state object
  useEffect(() => {
    dispatch({type: 'INIT'});
    console.log('init herer', state);
  }, []);

  useEffect(() => {
    fetch(`${apiUrl}/pic`, { method: 'GET' })
    // fetch(`https://kiavtg5p5f.execute-api.us-east-1.amazonaws.com/prod/pic`, { method: 'GET'})
      .then((response) => response.json())
      .then((values) => {
        console.log('values', values)
        dispatch({type: 'SET_ASSETS', provider: [...values]});
      })
  }, []);

  console.log('connect state.........', state);

  return (
    <MainContext.Provider value={{state, dispatch}}>
      {children}
    </MainContext.Provider>
  );
};

export {MainContext, Provider};
